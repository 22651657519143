import React from 'react';
import { Container, Col, Row} from 'react-bootstrap';
import EPGILogoFooter from "../../assets/footer/east-point-logo-light-footer.webp";
import UniversityLogo from "../../assets/footer/university_logo.webp";

function Footer() {
  return (
    <div className='footer_container_main footer'>
      <Container className='mycontainer'>
        <Row className='pb-5'>
          <Col xs={12} lg={9}>
            <Row className='g-3 g-lg-0 pt-4'>
              <Col xs={6} md={4} lg={2}>
                <p className='footer_headings'>Quick Links</p>
                <div className='border-bottom-pink my-2 my-lg-4'></div>
                <ul>
                  <li>Home</li>
                  <li>About Us</li>
                  <li>Courses</li>
                  <li>Admissions</li>
                  <li>Facilities</li>
                </ul>
              </Col>
              <Col xs={6} md={4} lg={3}>
              <p className='footer_headings' >Support</p>
                <div className='border-bottom-pink my-2 my-lg-4'></div>
                <ul>
                  <li> Faculty Login
                  </li>
                  <li> Student Login
                  </li>
                  <li> Fee Payment </li>
                  <li> E - Notice Board </li>
                </ul>
              </Col>
              <Col xs={6} md={4} lg={3}>
              <p className='footer_headings' >East Point Academy </p>
                <div className='border-bottom-pink my-2 my-lg-4'></div>
                <div className="joey-regular font-18 lh-lg secondary-blue-text">
                            <p>#10, 7th Main, 80 Ft Road, <br />
                            HRBR Layout, Kalyan Nagar, <br />
                            Bengaluru 560043 <br />
                            Karnataka, India
                            </p>         
                            </div>
              </Col>
              <Col xs={6} md={4} lg={4}>
              <p className='footer_headings' >Jnanaprabha Campus </p>
                <div className='border-bottom-pink my-2 my-lg-4'></div>
                <div className="joey-regular font-18 lh-lg secondary-blue-text">
                  <p>Bidarahalli, Virgo Nagar Post <br />
                            Off Old Madras Road <br />
                            Bengaluru 560049 <br />
                            Karnataka, India
                            </p>        
                            </div>
                            
                <ul>
                <li> <a href="tel:+917204229999">M: +91 720 422 9999</a><br />
                                        <a className='foot_mail' href="mailto:admissions@eastpoint.ac.in">admissions@eastpoint.ac.in</a>
                                    </li>
                </ul>
              </Col>
            </Row>
            <Row className='g-3 g-lg-0 pt-4'>
              <Col xs={6} lg={3}>
              <p className='footer_headings' >Institutions</p>
                <div className='border-bottom-pink my-2 my-lg-4'></div>
                <ul>
                  <li>Medical</li>
                  <li>Allied Health Science </li>
                  <li>Engineering </li>
                  <li>Higher Education </li>
                  <li>Nursing</li>
                  <li>Physiotherapy </li>
                  <li>Pharmacy </li>
                  <li>Pre University </li>
                  <li>School </li>
                </ul>
              </Col>
              <Col xs={12} md={6} lg={5}>
              <p className='footer_headings' >Documents & Downloads</p>
                <div className='border-bottom-pink my-2 my-lg-4'></div>
                <ul>
                  <li> Student Handbook </li>
                  <li>Newsletter</li>
                </ul>
              </Col>
              <Col xs={12} lg={3}>
                <Row>
                  <Col xs={6} lg={12}>
                    <i class="bi bi-envelope"></i>
                    <div className='border-bottom-pink my-2 my-lg-4'></div>
                    <ul>
                      <li><a className='foot_mail' href="mailto:admissions@eastpoint.ac.in"> admissions@eastpoint.ac.in </a></li>
                    </ul>
                  </Col>
                  <Col xs={6} lg={12}>
                  <i class="bi bi-telephone"></i>
                    <div className='border-bottom-pink my-2 my-lg-4'></div>
                    <ul>
                    <li> <a href="tel:91 72042 29999">Mob: +91 720 422 9999 </a></li>
                    <li> <a href="tel:080 2847 2999"> Ph: 080 2847 2999</a></li>
                    <li> <a href="fax:080 2507 3904"> Fax: 080 2507 3904</a></li>
                    </ul>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xs={12} lg={3} className='footer-social-links'>
              <div className='footer_logos'>
                  <div className='eastpoint_footer_logo' >
                    <img src={EPGILogoFooter} alt='East Point Logo' className='w-100' />
                  </div>
                  <div className='university_footer_logos' >
                    <img src={UniversityLogo} alt='East Point Logo' className='w-100' />
                  </div>
              </div>
              <div className='social-media_icons'>
                <p className='footer_headings' >Follow Us</p>
                <div className='border-bottom-pink my-2 my-lg-4'></div>
                <ul>
                  <li>
                    <a href='#'>
                    <i class="bi bi-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href='#'>
                    <i class="bi bi-linkedin"></i>
                    </a>
                  </li>
                  <li>
                    <a href='#'>
                    <i class="bi bi-twitter-x"></i>
                    </a>
                  </li>
                  <li>
                    <a href='#'>
                    <i class="bi bi-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div className='location_map_footer'>
                <p className='footer_headings' >Location Map</p>
                <div className='border-bottom-pink my-2 my-lg-4'></div>
                <div style={{ width: "100%", height: "100%" }}>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.7702631710063!2d77.71213271463621!3d13.050290216678142!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1019aaaaaaab%3A0x2a4d23a66cafaaf7!2sEast%20Point%20Group%20of%20Institutions!5e0!3m2!1sen!2sin!4v1652376010156!5m2!1sen!2sin"
                    width="100%"
                    height="100%"
                    style={{ border: 0 }}
                    title="Map Of EPGI"
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
          </Col>
        </Row>
        <div className='footer-section mt-4'>
          <Container>
            <Row>
              <Col xs={12} md={8} lg={7}>
                <ul>
                  <li>Terms of use</li>
                  <li>Disclaimer</li>
                  <li>Privacy</li>
                  <li> © 2024 East Point</li>
                  <li>All rights reserved</li>
                </ul>
              </Col>
              <Col xs={12} md={4} lg={5} className='text-lg-end'>
                <span>powered by EPGI</span>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>
    </div>
  )
}

export default Footer