// Our Academic Alliance Partners
import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../styles/HomeStyle.css";
import { Container, Row } from 'react-bootstrap';
import ARMLogo from "../../assets/hmpg_our_academic_alliance_partners/hmpg_arm.webp";
import AWSLogo from "../../assets/hmpg_our_academic_alliance_partners/hmpg_aws.webp";
import CISCOLogo from "../../assets/hmpg_our_academic_alliance_partners/hmpg_cisco.webp";
import GOOGLECLOUDLogo from "../../assets/hmpg_our_academic_alliance_partners/hmpg_googlecloud.webp";
import INFOSYSLogo from "../../assets/hmpg_our_academic_alliance_partners/hmpg_infosys.webp";
import MEDINILogo from "../../assets/hmpg_our_academic_alliance_partners/hmpg_medini.webp";
import TEXASLogo from "../../assets/hmpg_our_academic_alliance_partners/hmpg_texas.webp";
import UIPATHLogo from "../../assets/hmpg_our_academic_alliance_partners/hmpg_uipath.webp";
import VMWARELogo from "../../assets/hmpg_our_academic_alliance_partners/hmpg_vmware.webp";


var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 7,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]
  };

function Section2() {
  return (
    <>
    <Container className='mycontainer oaap_mn_cont pb-5 pt-4'>
      <Row xs={7} md={7} className="hmpg_section1_oaap_logos gy-3 highlights joey-bold g-xl-3">
      <h4 className="hmpg_headings_h4">Our Academic Alliance Partners </h4>
    <div className="slider-container">
    <Slider {...settings}>
      <div className='oaap_hmpg_logo_item'>
        <img src={ARMLogo} alt="ARM University" class="w-100" loading="lazy" />
      </div>
      <div className='oaap_hmpg_logo_item'>
        <img src={AWSLogo} alt="AWS" class="w-100" loading="lazy" />
      </div>
      <div className='oaap_hmpg_logo_item'>
        <img src={CISCOLogo} alt="CISCO" class="w-100" loading="lazy" />
      </div>
      <div className='oaap_hmpg_logo_item'>
        <img src={GOOGLECLOUDLogo} alt="Google Cloud" class="w-100" loading="lazy" />
      </div>
      <div className='oaap_hmpg_logo_item'>
        <img src={INFOSYSLogo} alt="Infosys" class="w-100" loading="lazy" />
      </div>
      <div className='oaap_hmpg_logo_item'>
        <img src={MEDINILogo} alt="Medini" class="w-100" loading="lazy" />
      </div>
      <div className='oaap_hmpg_logo_item'>
        <img src={TEXASLogo} alt="Texas" class="w-100" loading="lazy" />
      </div>
      <div className='oaap_hmpg_logo_item'>
        <img src={UIPATHLogo} alt="UiPath" class="w-100" loading="lazy" />
      </div>
      <div className='oaap_hmpg_logo_item'>
        <img src={VMWARELogo} alt="VmWare" class="w-100" loading="lazy" />
      </div>
    </Slider>

    </div>
         </Row>
      </Container>
      </>
  )
}

export default Section2;