import React from 'react';
import { Container, Nav, Navbar, NavDropdown  } from 'react-bootstrap';
import { Link } from "react-router-dom";
import EpcheLogo from "../../assets/epche/epche-logo.webp";
import EPCHEMessage from '../../pages/Institutions/epcheMessage';
import "../../styles/GlobalStyle.css";

function epcheMenu() {
  return (
    <>
      <Navbar className='epche_institutions_menu institutions_hmpg_menu_navbar'>
        <Container className='flex-wrap'>
          <Navbar.Brand href="#">
            <Link to="/" className='epchelogo'>
              <img src={EpcheLogo} alt='East Point College of Higher Education' className='img-fluid w-100' />
            </Link>
          </Navbar.Brand>
          <Nav className="ms-auto epche_institutions_nav flex-wrap">
            <Nav.Link as={Link} to="/epche-overview">Overview</Nav.Link>
            <Nav.Link as={Link} to="/epche-message">Principal's Message</Nav.Link>
            <Nav.Link as={Link} to="/epche-department-managements">Departments</Nav.Link>
            <Nav.Link as={Link} to="/epche-programs-bba-business">Programs</Nav.Link>
            <Nav.Link as={Link} to="/epche-whats-happening">What's Happening?</Nav.Link>
          </Nav>
        </Container>
      </Navbar>
      
    </>
  )
}

export default epcheMenu;