import { Container, Card, Col, Row } from 'react-bootstrap';
import EnquireNow from "../../assets/hero/enquire-now.webp";
import CallNowBTN from "../../assets/hero/callnow_button01.webp";
import WhatsAppBTN from "../../assets/hero/whatsapp_animationzif.webp";


function GridExample() {
  return (
    <>
      <Container className='mycontainer'>
      <div className='hmpg_buttons'>
      <div className="HMPGwhatsapp">
            <a href="https://wa.me/919901756909" target="_blank">
                <img src={WhatsAppBTN} alt="Whatsapp" className=""
                    loading="lazy" /></a>
        </div>
        <div className="whatsapp HMPGcall_now_mera_wala">
            <a href="tel:+917204229999" target="_blank">
                <img src={CallNowBTN} alt="Call Now" className=""
                    loading="lazy" /></a>
        </div>

        <div className="HMPGenquire-now">
            
            <button type="button" className="border-0 outline-0 npfWidget-dbd7a3897e44e6974816149dfb77234a">
                <img src={EnquireNow} alt="Enquire Now" className=""
                    loading="lazy" />
            </button>
        </div>
      </div>
      <Row xs={7} md={7} className="hmpg_section1_data gy-3 highlights joey-bold g-xl-3">
        <Col xs={6} md={3} lg={3} xl>
          <Card className='h-100'>
            <Card.Body className='px-1'>
              <Card.Title>10000+</Card.Title>
              <Card.Text>
              Students
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={6} md={3} lg={3} xl>
          <Card className='h-100'>
            <Card.Body className='px-1'>
              <Card.Title>600+</Card.Title>
              <Card.Text>
              Teaching Faculty
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={6} md={3} lg={3} xl> 
          <Card className='h-100'> 
            <Card.Body className='px-1'>
              <Card.Title>66+</Card.Title>
              <Card.Text>
              Programs Offered
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={6} md={3} lg={3} xl>
          <Card className='h-100'>
            <Card.Body className='px-1'>
              <Card.Title>500+</Card.Title>
              <Card.Text>
              Publications
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={6} md={3} lg={3} xl>
          <Card className='h-100'>
            <Card.Body className='px-1'>
              <Card.Title>100+</Card.Title>
              <Card.Text>
              Acre Campus
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={6} md={3} lg={3} xl>
          <Card className='h-100'>
            <Card.Body className='px-1'>
              <Card.Title>300+</Card.Title>
              <Card.Text>
              Companies Visited
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={6} md={3} lg={3} xl>
          <Card className='h-100'>
            <Card.Body className='px-1'>
              <Card.Title>35000+</Card.Title>
              <Card.Text>
              Alumni Across the Globe
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

    </Row>
      </Container>
    </>
  );
}

export default GridExample;