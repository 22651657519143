import React from 'react';
import { Container, Col, Row, Breadcrumb, Nav, Tab } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../styles/GlobalStyle.css";
import "../../styles/HomeStyle.css";
import Header from '../../components/Layouts/Header';
import EPCHEMenu from '../../components/Reusablecomponents/epcheMenu';
import Footer from '../../components/Layouts/Footer';
import DepartmentProfileImg1 from '../../assets/epche/EPCHE-8.webp';
import EPCHEHODimg from '../../assets/epche/Epche_bba_Suma_Jayakumar.webp';

function epcheDepartmentManagements() {
  return (
    <div>
        <div className='main_body_bg_img'>
        <Header />
        <EPCHEMenu />
        <Container>
        <Breadcrumb>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item >
            East Point Institutions
            </Breadcrumb.Item>
            <Breadcrumb.Item active>East Point College of Higher Education (EPCHE) -
            Department</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
            <Col xs={12} className='mb-4'>
                <h1>Department</h1>
                <div className='border-bottom-pink my-2 my-lg-4'></div>
            </Col>
        </Row>
        </Container>
        <div className='bg-white'>
            <Container className='pb-5 epchedepartmentmanagements'>
                <Row className='pb-5'>
                <Tab.Container defaultActiveKey="profile">
            <Row>
          {/* Left Side Navigation */}
          <Col sm={3} className=" p-3">
            <Nav className="flex-column programs_tabs_links font-17 joey-bold">
              <Nav.Item>
                <Nav.Link eventKey="profile">Department Profile</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="hod">HOD Message</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="overview">Program Overview</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>

          {/* Right Side Content */}
          <Col sm={9} className="p-3">
            <div className='department_main_container'>
            <h2 className='font-38 joey-bold'>Department of Management Studies (UG) </h2>
            <div className="dept_civil_underline"></div>
            <Tab.Content>
              <Tab.Pane eventKey="profile">
                <h4 className='joey-bold' >Department Profile</h4>
                <img
                  src={DepartmentProfileImg1}
                  alt="Department"
                  className="w-100 img-fluid mb-3"
                />
                <p className='mb-4 epche_overview_para font-19 pt-3 joey-regular'>
                The Department of Management Studies (UG) was established in 1999, affiliated to Bangalore North University (BNU) and is approved by the Government of Karnataka, offers BBA and BBA-Aviation management<br /><br />
The department is dedicated to preparing students for success. The key to its success lies in its seasoned educators, each driven by a profound passion for moulding young minds. A significant number of the esteemed faculty members possess advanced degrees and boast extensive industry experience, enriching the classroom with practical insights that extend well beyond the confines of textbooks. These educators transcend traditional teaching methods, actively fostering interactive sessions, integrating case studies, and emphasizing practical applications.<br /><br /> 
Our state-of-the-art infrastructure, featuring modern classrooms equipped with cutting-edge technology and dedicated spaces for interactive learning, serves as the ideal backdrop for an immersive educational experience. Additionally, our institution provides top-notch computer lab facilities, further enhancing students' access to resources required to meet their academic needs. Our library facilities boast a rich collection of books, magazines, and journals, offering students a comprehensive repository of knowledge to support their academic endeavours. <br /><br />
BBA programs offer transformative value-added courses, in addition to the standard curriculum, to enhance employability and industry readiness. These courses provide a well-rounded education aligned with dynamic business needs. This diverse skill set positions our students competitively in the job market. Emphasizing holistic development, we also provide a vibrant array of extracurricular and co curricular opportunities. The department regularly organizes seminars, workshops, conferences, and industrial visits, fostering a rich learning environment. Additionally, student activity clubs focus on co curricular activities, enhancing the overall development of our students.
                </p>
              </Tab.Pane>
              <Tab.Pane eventKey="hod">
                <h4 className='joey-bold' >HOD Message</h4>
                <Row>
                    <Col xs={12} md={8}>
                    <p className='mb-4 epche_overview_para font-19 pt-3 joey-regular'>
                    "When we strive to become better than we are, everything around us becomes better too."-Bhagavad Gita 18.58<br />
			
			Our department is dedicated to fostering a stimulating and inclusive academic environment that empowers students to excel in business and beyond. We are committed to providing a high-quality education that equips our students with the knowledge, skills, and ethical principles necessary to thrive in today's competitive global marketplace.<br />
			
			At the heart of our department's mission is a dedication to excellence in teaching, research, and community engagement. Our esteemed faculty members are experts in their respective fields. They are passionate about delivering innovative and relevant coursework that prepares our students for success in a rapidly changing business landscape.<br />
			
			In addition to our rigorous academic programs, we offer numerous opportunities for students to engage in experiential learning, Industrial visits, Internships, and collaborative research projects. We believe that these hands-on experiences are essential for developing well-rounded, career-ready graduates.
                    </p>
                    </Col>
                    <Col xs={12} md={4} className='department_hod_img principal_epche_img_div'>
                        <img src={EPCHEHODimg} alt='East Point Higher Education HOD' className='w-100 principal_image_institutions epche_dprtmnt_hod_img' loading='lazy' />
                        <h5>Dr. Janet Mary Coates</h5>
                        <div className="epgi_dept_pri_name">
                            Principal, East Point College of Higher Education <br />
                            <a href="mailto:Principal.epche@eastpoint.ac.in">principal.epche@eastpoint.ac.in</a><br />
                        </div>
                    </Col>
                </Row>
                <p className='mb-4 epche_overview_para font-19 pt-3 joey-regular'>
                Our commitment to diversity, equity, and inclusion is central to our values, and we strive to create an environment where every student can thrive and reach their full potential. We welcome individuals from all backgrounds and perspectives to join our department and contribute to the rich tapestry of ideas and experiences that strengthen us as a community.
                    </p>
                
              </Tab.Pane>
              <Tab.Pane eventKey="overview">
                <h4 className='joey-bold'>Program Overview - BBA </h4>
                <p className='mb-4 epche_overview_para font-19 pt-3 joey-regular'>
                The Bachelor of Business Administration (BBA) is a comprehensive three-year undergraduate program, aiming to equip students with essential knowledge and competencies for a successful career in the dynamic business world. The curriculum covers key areas like finance, marketing, human resources, entrepreneurship, economics, and business ethics, offering a holistic understanding of the corporate landscape. Opportunities abound in sectors like banking, marketing, IT, business, insurance, and MNCs. Further specialization through CA, ICWA, CS, MBA, MFA, MIB, and MTA courses enhances career prospects.
                </p>
                <h4 className='joey-bold'>Program Objectives - BBA </h4>
                <ol className='paralists_vis font-19'>
                    <li className='ms-3 pb-4'>To develop entrepreneurism by equipping them with all the skills and knowledge required to start a business.</li>
                        
                    <li className='ms-3 pb-4'>To develop ethical managers with inter disciplinary knowledge.</li>
                        
                    <li className='ms-3 pb-4'>To enable students to apply knowledge of management theories and practices to solve business problems.</li>
                    <li className='ms-3 pb-4'>To promote ethical and value-based leadership ability.</li>
                    <li className='ms-3 pb-4'>To equip students for the world of work, particularly the work of the future.</li>
                    <li className='ms-3 pb-4'>To enable students to demonstrate use of appropriate techniques to effectively manage business challenges.</li>
                    <li className='ms-3 pb-4'>To encourage analytical and critical thinking abilities for business decision making.</li>
                    <li className='ms-3 pb-4'>To empower students to take up competitive examinations like UPSC, KPSC, and other examinations.</li>
                        </ol>
        <h4 className='joey-bold'>Program Outcomes - BBA </h4> 
        <ol className='paralists_vis font-19'>
                    <li><span className="dept_civil_bold">Disciplinary Knowledge </span>
                        Capability of executing comprehensive knowledge and understanding of one or more disciplines that form part of commerce. </li><br />
                        
                   
                        
                    <li><span className="dept_civil_bold"> Critical Thinking  </span>
                        Ability to engage in reflective and independent thinking by understanding the concepts in every area of Commerce and Business. <br />
                        Ability to examine the results and apply them to various problems appearing in different branches of Commerce and Business. </li><br />
                        
                    <li><span className="dept_civil_bold">Problem Solving  </span>
                        Capability to reduce a business problem and apply the classroom learning into practice to offer a solution for the same. <br />
                        Capabilities to analyse and synthesize data and derive inferences for valid conclusions. <br />
                        Able to comprehend solutions to sustain problems originating in the diverse management areas such as Finance, Marketing, Human Resource, Taxation and so on.</li><br />
                        
                    <li><span className="dept_civil_bold"> Research Related Skills   </span>
                        Ability to search for, locate, extract, organise, evaluate, and use or present information that is relevant to a particular topic. <br />
                        Ability to identify the developments in various branches of Commerce and Business. </li><br />
                        
                    <li><span className="dept_civil_bold"> Information and Communication Technology (ICT) Digital Literacy   </span>
                        Capability to use various ICT tools (like spreadsheet) for exploring, analysis, and utilizing the information for business purposes. </li><br />
                        
                    <li><span className="dept_civil_bold"> Self-Directed Learning   </span>
                        Capability to work independently in diverse projects and ensure detailed study of various facets of Commerce and Business. </li><br />
                        
                    <li><span className="dept_civil_bold"> Moral and Ethical Awareness / Reasoning  </span>
                        Ability to ascertain unethical behaviour, falsification, and manipulation of information. ii. Ability to manage self and various social systems. </li><br />
                        
                    <li><span className="dept_civil_bold"> Life-Long Learning   </span>
                        Capability of self-paced and self-directed learning aimed at personal development and for improving knowledge/skill development and reskilling in all areas of Commerce. </li><br />
                </ol> 
                <h4 className='joey-bold'>Program Overview - BBA-Aviation Management </h4>  
                <p className='mb-4 epche_overview_para font-19 pt-3 joey-regular'>
                Bachelor of Business Administration (BBA) in Aviation Management is a three-year undergraduate program tailored for individuals aspiring to pursue careers in the aviation industry. The program's specialized nature responds to a significant surge in demand in the aviation market, propelled by the dynamic growth and complexity of the aviation industry. Furthermore, the course broadens its scope by encompassing subjects such as marketing management, human resource management, financial planning, and digital transformation. BBA Aviation graduates have a plethora of exciting career opportunities in the dynamic aviation industry.
                    </p>    
                <h4 className='joey-bold'>Program Objectives - BBA-Aviation Management</h4>
                <ol className='paralists_vis font-19'>
                    <li className='ms-3 pb-4'>To have a clear and comprehensive understanding of aviation business and its operational environment, such as airports, airlines, cargo, safety, and regulations.</li>
                               
                    <li className='ms-3 pb-4'>To develop Aviation Global Leaders.</li>
                                
                    <li className='ms-3 pb-4'>To provide hands on experience on most widely used computerized reservation system (CRS) for air ticketing and hotel reservation.</li>
                    <li className='ms-3 pb-4'>To demonstrate a high capability of all airline business management aspect (e.g. airline business law, aircraft types, basic reservation, and ticketing).</li>
                    <li className='ms-3 pb-4'>To empower students to take up competitive examinations of UPSC, KPSC, and other competitive examinations.</li>
                </ol> 
                <h4 className='joey-bold'>Program Outcomes - BBA-Aviation Management</h4>
                <ol className='paralists_vis font-19'>
                    <li><span className="dept_civil_bold"> Disciplinary Knowledge  </span>
                        Capability of executing comprehensive knowledge and understanding of one or more disciplines that form part of commerce. </li><br />
                        
                    <li><span className="dept_civil_bold"> Critical Thinking   </span>
                        Ability to engage in reflective and independent thinking by understanding the concepts in every area of Commerce and Business. <br />
                        Ability to examine the results and apply them to various problems appearing in different branches of Commerce and Business. </li><br />
                        
                    <li><span className="dept_civil_bold"> Problem Solving   </span>
                        Capability to reduce a business problem and apply the classroom learning into practice to offer a solution for the same. <br />
                        Capabilities to analyse and synthesize data and derive inferences for valid conclusions. <br />
                        Able to comprehend solutions to sustain problems originating in the diverse management areas such as Finance, Marketing, Human Resource, Taxation and so on.</li><br />
                        
                    <li><span className="dept_civil_bold">	Research Related Skills  </span>
                        Ability to search for, locate, extract, organise, evaluate, and use or present information that is relevant to a particular topic. <br />
                        Ability to identify the developments in various branches of Commerce and Business. </li><br />
                        
                    <li><span className="dept_civil_bold"> Information and Communication Technology (ICT) Digital Literacy </span>
                        Capability to use various ICT tools (like spreadsheet) for exploring, analysis, and utilizing the information for business purposes. </li><br />
                        
                    <li><span className="dept_civil_bold"> Self-Directed Learning   </span>
                        Capability to work independently in diverse projects and ensure detailed study of various facets of Commerce and Business. </li><br />
                        
                    <li><span className="dept_civil_bold"> Moral and Ethical Awareness / Reasoning  </span>
                        Ability to ascertain unethical behaviour, falsification, and manipulation of information. ii. Ability to manage self and various social systems. </li><br />
                        
                    <li><span className="dept_civil_bold">	Life-Long Learning   </span>
                        Capability of self-paced and self-directed learning aimed at personal development and for improving knowledge/skill development and reskilling in all areas of Commerce. </li><br />
                </ol>              
              </Tab.Pane>
            </Tab.Content>
            </div>
          </Col>
        </Row>
      </Tab.Container>
                </Row> 
            </Container> 
        </div>  
       </div>  
       <Footer />       
    </div>
  )
}

export default epcheDepartmentManagements;