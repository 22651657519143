import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './pages/Home/Home';
import './App.css'
import Introduction from './pages/Home/AboutUs/Introduction';
import EPCHEHome from './pages/Institutions/institutionsEpcheHome'; 
import EPCHEMessage from './pages/Institutions/epcheMessage';
import EPCHEOverview from './pages/Institutions/epcheOverview';
import EPCHEDepartmentManagements from './pages/Institutions/epcheDepartmentManagements';
import EPCHEProgramBBABusiness from './pages/Institutions/epcheProgramsBbaBusiness';
import EPCHEWhatsHappening from './pages/Institutions/epcheWhatsHappening';

// import Header from './components/Layouts/Header';
// import Footer from './components/Layouts/Footer';

function App() {
  return (
    <Router>
      {/* <Header /> */}
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/introduction' element={<Introduction />} />
        <Route path='/epche-home' element={<EPCHEHome />} />
        <Route path='/epche-overview' element={ <EPCHEOverview /> } />
        <Route path='/epche-message' element={<EPCHEMessage />} />
        <Route path='/epche-department-managements' element={<EPCHEDepartmentManagements />} />
        <Route path='/epche-programs-bba-business' element={<EPCHEProgramBBABusiness />} />
        <Route path='/epche-whats-happening' element={<EPCHEWhatsHappening />} />
        
      </Routes>
      {/* <Footer /> */}
    </Router>
  )
}

export default App
