import React from 'react';
// import Layout from '../../components/Layouts/Layout';
import "../../styles/HomeStyle.css";
// import Section1 from './Section1';
// import VideoPlayer from './Videoplayer';
import Sections from './Sections';
import Section3 from './Section3';
import Section4 from './Section4';
import Section5 from './Section5';
import Section6 from './Section6';
import Header from '../../components/Layouts/Header';
import Footer from '../../components/Layouts/Footer';


function Home() {
  return (
    <div className='main_body_bg_img'>
        {/* <Layout>
            
            <Sections />
            <Section3 />
            <Section4 />
            <Section5 />
            <Section6 />
        </Layout> */}
        <Header />
            <Sections />
            <Section3 />
            <Section4 />
            <Section5 />
            <Section6 />
          <Footer />
    </div>
  )
}

export default Home;