import React, { useState, useEffect, useRef } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";


function VideoPlayer() {
  const videoRef = useRef(null);
  const [isMuted, setIsMuted] = useState(true);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(0);

  const videoSources = [
    "https://eastpoint.ac.in/web/themes/default/images/east_point_college4aprl.mp4",
    "https://eastpoint.ac.in/web/themes/default/images/samskrrithi2k22_4aprl.mp4",
  ];

  // Preload all videos
  useEffect(() => {
    videoSources.forEach((source) => {
      const link = document.createElement("link");
      link.rel = "preload";
      link.as = "video";
      link.href = source;
      document.head.appendChild(link);
    });
  }, []);

  useEffect(() => {
    playVideo(currentVideo);
  }, [currentVideo]);

  const playVideo = (index) => {
    if (videoRef.current) {
      videoRef.current.src = videoSources[index];
      videoRef.current.muted = isMuted;
      videoRef.current.play()
        .catch((err) => console.error("Autoplay failed: ", err));
      setIsPlaying(true);
    }
  };

  const togglePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const toggleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  const toggleFullScreen = () => {
    if (videoRef.current) {
      if (!isFullScreen) {
        if (videoRef.current.requestFullscreen) {
          videoRef.current.requestFullscreen();
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        }
      }
      setIsFullScreen(!isFullScreen);
    }
  };

  const handleVideoEnd = () => {
    const nextVideo = (currentVideo + 1) % videoSources.length;
    setCurrentVideo(nextVideo);
  };

  return (
    <>
    <Container fluid className="p-3 hero_video_mn_container">
      <Row>
        <Col xs={12} >
      <div className="video-controls">
        {/* Video Player */}
        <video
          ref={videoRef}
          width="100%"
          height="100%"
          autoPlay
          muted={isMuted}
          onEnded={handleVideoEnd}
          preload="auto"
        ></video>

        {/* Controls */}
        <div className="Videocontrols_buttons controls justify-content-between align-items-center">
          <div>
            <Button variant="primary" onClick={togglePlayPause}>
              {isPlaying ? "Pause" : "Play"}
            </Button>
            <Button variant="secondary" onClick={toggleMute} className="ms-2">
              {isMuted ? "Unmute" : "Mute"}
            </Button>
          </div>

          <Button
            variant="outline-primary"
            onClick={toggleFullScreen}
            className="fullscreen-button"
          >
            <img
              src="https://eastpoint.ac.in/web/themes/default/images/expand.png"
              alt="Fullscreen"
              style={{ width: "20px", height: "20px" }}
            />
            {/* <i class="bi bi-arrows-angle-expand" style={{ width: "20px", height: "20px", color:'green', fontWeight:'900' }}></i> */}
          </Button>
        </div>
      </div>
      </Col>
      <Col xs={12} xl={5} className="apply-now-section-home">
        <h1>Promoting Education with Human 
          Values to the Real World</h1>
          <Button className="pink-bg" >APPLY NOW</Button>
      </Col>
      </Row>
    </Container>
    
    </>
  );
}

export default VideoPlayer;
