import React from 'react';
import { Container, Col, Row, Card, Button } from 'react-bootstrap';
import Slider from "react-slick";
import Footballpray from "../../assets/hmpg_socialmedia_sliders/rae_football_groundopen_pray.webp";
import RAEGraduationDay from "../../assets/hmpg_socialmedia_sliders/rae_graduationaday_celebration.webp";
import RAEMeetupGov from "../../assets/hmpg_socialmedia_sliders/rae_meetupgov1.webp"



const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

function Section4() {
  return (
    <Container className='mycontainer '>
      <Row>
        <Col lg={4} className='socialmedia_slider_arrows'>
        {/* Recent Activities */}
                        <div className="slider-container">
                    <Slider {...settings}>
                        <div className='positon-relative slider_card_bg'>
                        <Card>
                          <Card.Img variant="top" src={Footballpray} alt='East Point Football Ground' />
                          <Card.Body>
                          
                            <Card.Text>
                              "The inauguration of the Football & asketball Court at East Point "Jnanaprabha Campus, Bangalore in February, 2024 !!""
                            </Card.Text>
                            <Button variant="primary">View More <i class="bi bi-arrow-right"></i></Button>
                          </Card.Body>
                        </Card>
                        </div>
                        <div className='positon-relative slider_card_bg'>
                        <Card>
                          <Card.Img variant="top" src={RAEGraduationDay} alt='East Point Graduation Day' />
                          <Card.Body>
                          
                            <Card.Text>
                            "You Did it!! We are proud of you.... Best wishes for a future filled with triumphs." Graduation Day 2023!! - East Point College of Higher Education
                            </Card.Text>
                            <Button variant="primary">View More <i class="bi bi-arrow-right"></i></Button>
                          </Card.Body>
                        </Card>
                        </div>
                        <div className='positon-relative slider_card_bg'>
                        <Card>
                          <Card.Img variant="top" src={RAEMeetupGov} alt='East Point Government Leader Meet' />
                          <Card.Body>
                          
                            <Card.Text>
                            "Yuva Samvada" - with Sri Jyotiraditya M Scindia Honourable Minister for Civil Aviation, Govt of India was held on 28th March 2023 @EPGI Campus
                            </Card.Text>
                            <Button variant="primary">View More <i class="bi bi-arrow-right"></i></Button>
                          </Card.Body>
                        </Card>
                        </div>
                        
                        
                    </Slider>
                    </div>
                    <div className='socialmedias_sliders_heading'>
                      <h5>Recent Activities</h5>
                    </div>
        </Col>
        <Col lg={4} className='socialmedia_slider_arrows'>
        {/* Instagram feeds */}
                        <div className="slider-container">
                    <Slider {...settings}>
                        <div className='positon-relative slider_card_bg'>
                        <Card>
                          <Card.Img variant="top" src={Footballpray} alt='East Point Football Ground' />
                          <Card.Body>
                          
                            <Card.Text>
                              "The inauguration of the Football & asketball Court at East Point "Jnanaprabha Campus, Bangalore in February, 2024 !!""
                            </Card.Text>
                            <Button variant="primary">View More <i class="bi bi-arrow-right"></i></Button>
                          </Card.Body>
                        </Card>
                        </div>
                        <div className='positon-relative slider_card_bg'>
                        <Card>
                          <Card.Img variant="top" src={RAEGraduationDay} alt='East Point Graduation Day' />
                          <Card.Body>
                          
                            <Card.Text>
                            "You Did it!! We are proud of you.... Best wishes for a future filled with triumphs." Graduation Day 2023!! - East Point College of Higher Education
                            </Card.Text>
                            <Button variant="primary">View More <i class="bi bi-arrow-right"></i></Button>
                          </Card.Body>
                        </Card>
                        </div>
                        <div className='positon-relative slider_card_bg'>
                        <Card>
                          <Card.Img variant="top" src={RAEMeetupGov} alt='East Point Government Leader Meet' />
                          <Card.Body>
                          
                            <Card.Text>
                            "Yuva Samvada" - with Sri Jyotiraditya M Scindia Honourable Minister for Civil Aviation, Govt of India was held on 28th March 2023 @EPGI Campus
                            </Card.Text>
                            <Button variant="primary">View More <i class="bi bi-arrow-right"></i></Button>
                          </Card.Body>
                        </Card>
                        </div>
                        
                        
                    </Slider>
                    </div>
                    <div className='socialmedias_sliders_heading'>
                      <h5>Social Media</h5>
                    </div>
        </Col>
        <Col lg={4} className='socialmedia_slider_arrows'>
        {/* Special Announcement */}
                        <div className="slider-container">
                    <Slider {...settings}>
                        <div className='positon-relative slider_card_bg'>
                        <Card>
                        <iframe height="205px" src="https://www.youtube.com/embed/VmcSTgBlXtw?si=NLdaufeSuPb02cnk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen=""></iframe>
                          <Card.Body>
                          
                            <Card.Text>
                            EAST POINT GROUP OF INSTITUTIONS <br /> RECIPIENT OF "TIMES BUSINESS AWARDS 2023" <br />FOR EXCELLENCE IN QUALIT EDUCATION
                            </Card.Text>
                            <Button variant="primary">View More <i class="bi bi-arrow-right"></i></Button>
                          </Card.Body>
                        </Card>
                        </div>
                        <div className='positon-relative slider_card_bg'>
                        <Card>
                        <iframe height="205px" src="https://www.youtube.com/embed/VmcSTgBlXtw?si=NLdaufeSuPb02cnk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                          <Card.Body>
                          
                            <Card.Text>
                            Glimpses of Mega Job Fair Organized by East Point and AWS on 23.09. 2022. <br />Heartiest Congratulations to all the students placed!!!<br />
                            East Point Mega Job Fair
                            </Card.Text>
                            <Button variant="primary">View More <i class="bi bi-arrow-right"></i></Button>
                          </Card.Body>
                        </Card>
                        </div>
                        <div className='positon-relative slider_card_bg'>
                        <Card>
                        <iframe height="205px" src="https://www.youtube.com/embed/cgsLQm38jDk?si=sktlQ565ccuv7woi" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                          <Card.Body>
                          
                            <Card.Text>
                            Silver Jubilee of EPGI was telecasted on Public TV News Channel on 19.12.2022
                            </Card.Text>
                            <Button variant="primary">View More <i class="bi bi-arrow-right"></i></Button>
                          </Card.Body>
                        </Card>
                        </div>
                        <div className='positon-relative slider_card_bg'>
                        <Card>
                        <iframe height="205px" src="https://www.youtube.com/embed/QAzQY1f1A54?si=DnEfgafCT7OSVOKL" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                          <Card.Body>
                          
                            <Card.Text>
                            Celebrating the journey of East Point Group of Institutions. Treading on the silvery trail we reach 25 years of realising the dream of the Founder Chairman Dr. S. M. Venkatpathi.<br /> Celebrating academic excellence for 25 years.
                            </Card.Text>
                            <Button variant="primary">View More <i class="bi bi-arrow-right"></i></Button>
                          </Card.Body>
                        </Card>
                        </div>
                        <div className='positon-relative slider_card_bg'>
                        <Card>
                        <iframe height="205px" src="https://www.youtube.com/embed/UbTCpVywDSg?si=42iIOULh4zAOldAe" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                          <Card.Body>
                          
                            <Card.Text>
                            Yuva Samvada with Shri Jyotiaditya M Scindia - Honorable Minister, Ministry of Civil Aviation, Government of India at 28 March 2023 at East Point Campus.
                            </Card.Text>
                            <Button variant="primary">View More <i class="bi bi-arrow-right"></i></Button>
                          </Card.Body>
                        </Card>
                        </div>
                        <div className='positon-relative slider_card_bg'>
                        <Card>
                        <iframe height="205px" src="https://www.youtube.com/embed/mQ4ZHOStHhg?si=ium7qcbWsy7tA2ku" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                          <Card.Body>
                          
                            <Card.Text>
                            Dr. S.M. Venkatpathi, the visionary founder of East Point Group of Institutions under the aegis of M.G. Charitable Trust
                            </Card.Text>
                            <Button variant="primary">View More <i class="bi bi-arrow-right"></i></Button>
                          </Card.Body>
                        </Card>
                        </div>
                        
                        
                    </Slider>
                    </div>
                    <div className='socialmedias_sliders_heading'>
                      <h5>Special Announcements</h5>
                    </div>
        </Col>
      </Row>
      </Container>
  )
}

export default Section4;