// Building Trust
// Feedback on Google Reviews

import React from 'react';
import { Container, Col, Row} from 'react-bootstrap';
import Slider from "react-slick";
import StudentRev1 from "../../assets/hmpg_building_trust_reviews/epcpt-student-1.webp";
import GoogleReview from "../../assets/hmpg_building_trust_reviews/google-review.webp";
import Rating from "../../assets/hmpg_building_trust_reviews/rating.webp";

const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: false,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
  };

function Section6() {
  return (
    <>
        <Container className='mycontainer'>
            <Row>
                <Col lg={12} className='text-center'>
                <div className='building_trust_hdings'>
                    <h2>Building Trust</h2>
                    <h5>Feedback on Google Reviews</h5>
                </div>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                <div className="slider-container">
                <Slider {...settings}>
                    <div className='item review_container'>
                        <Row>
                            <Col lg={3} xs={3}>
                                <img src={StudentRev1} alt='Student 1 East Point' className='StudentImg w-100' />
                            </Col>
                            <Col lg={9} xs={9}>
                                <p className="StudentName">Shiva S</p>
                                <img src={Rating} className='w-100 ratingimg' alt='East Point Rating' />
                            </Col>
                        </Row>
                        <p>
                        Hi there, my name is Shiva Sudarshan.
                        My experience with East Point is "wow". We have received great support from
                        college faculties towards academics. We got encouragement to participate in
                        sports and cultural activities, thanks to the management for having that.
                
                        </p>
                        <div className='text-start'>
                            <p className='spanish-grey joey-bold font-18 mb-1 mt-3 text-start'>Posted</p>
                            
                            <a href="https://g.co/kgs/NRvC1Q" target="_blank" class="text-decoration-none">
                            <img src={GoogleReview}
                                className="googlereviewimg" loading="lazy"
                                alt="Google Review" /></a>
                            
                        </div>
                    </div>
                    <div className='item review_container'>
                        <Row>
                            <Col lg={3} xs={3}>
                                <img src={StudentRev1} alt='Student 1 East Point' className='StudentImg w-100' />
                            </Col>
                            <Col lg={9} xs={9}>
                                <p className="StudentName">Shiva S</p>
                                <img src={Rating} className='w-100 ratingimg' alt='East Point Rating' />
                            </Col>
                        </Row>
                        <p>
                        Hi there, my name is Shiva Sudarshan.
                        My experience with East Point is "wow". We have received great support from
                        college faculties towards academics. We got encouragement to participate in
                        sports and cultural activities, thanks to the management for having that.
                
                        </p>
                        <div className='text-start'>
                            <p className='spanish-grey joey-bold font-18 mb-1 mt-3 text-start'>Posted</p>
                            
                            <a href="https://g.co/kgs/NRvC1Q" target="_blank" class="text-decoration-none">
                            <img src={GoogleReview}
                                className="googlereviewimg" loading="lazy"
                                alt="Google Review" /></a>
                            
                        </div>
                    </div>
                    <div className='item review_container'>
                        <Row>
                            <Col lg={3} xs={3}>
                                <img src={StudentRev1} alt='Student 1 East Point' className='StudentImg w-100' />
                            </Col>
                            <Col lg={9} xs={9}>
                                <p className="StudentName">Shiva S</p>
                                <img src={Rating} className='w-100 ratingimg' alt='East Point Rating' />
                            </Col>
                        </Row>
                        <p>
                        Hi there, my name is Shiva Sudarshan.
                        My experience with East Point is "wow". We have received great support from
                        college faculties towards academics. We got encouragement to participate in
                        sports and cultural activities, thanks to the management for having that.
                
                        </p>
                        <div className='text-start'>
                            <p className='spanish-grey joey-bold font-18 mb-1 mt-3 text-start'>Posted</p>
                            
                            <a href="https://g.co/kgs/NRvC1Q" target="_blank" class="text-decoration-none">
                            <img src={GoogleReview}
                                className="googlereviewimg" loading="lazy"
                                alt="Google Review" /></a>
                            
                        </div>
                    </div>
                   
                </Slider>
                </div>
                </Col>
            </Row>

        </Container>

        
        <div className='building_trust_reviews_container'>

        </div>
    </>
  )
}

export default Section6