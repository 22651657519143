import React from 'react';
import { Container, Col, Row, Breadcrumb, Nav, Tab } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../styles/GlobalStyle.css";
import "../../styles/HomeStyle.css";
import Header from '../../components/Layouts/Header';
import EPCHEMenu from '../../components/Reusablecomponents/EpcheMenu';
import Footer from '../../components/Layouts/Footer';
import EPCHEWayOfLifeBBA from '../../assets/epche/epche_way_of_life_1.webp';
import QuotationMarck from "../../assets/epche/quotation-mark.webp";

function epcheProgramsBbaBusiness() {
  return (
    <div>
        <div className='main_body_bg_img'>
        <Header />
        <EPCHEMenu />
        <Container>
        <Breadcrumb>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item >
            East Point Institutions
            </Breadcrumb.Item>
            <Breadcrumb.Item active>East Point College of Higher Education (EPCHE) -
            Departments & Programs</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
            <Col xs={12} className='mb-4'>
                <h1>Departments & Programs</h1>
                <div className='border-bottom-pink my-2 my-lg-4'></div>
            </Col>
        </Row>
        </Container>
        <div className='bg-white'>
            <Container className='pb-5 epchedepartmentmanagements'>
                <Row className='pb-5'>
                <Tab.Container defaultActiveKey="profile">
            <Row>
          {/* Left Side Navigation */}
          <Col sm={3} className=" p-3">
            <Nav className="flex-column programs_tabs_links font-17 joey-bold">
              <Nav.Item>
                <Nav.Link eventKey="profile">Bachelor of Business Administration (BBA)</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="hod">HOD Message</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="overview">Program Overview</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>

          {/* Right Side Content */}
          <Col sm={9} className="p-3">
            <div className='department_main_container'>
            <h2 className='font-38 joey-bold'>Bachelor of Business Administration (BBA) </h2>
            <div className="dept_civil_underline"></div>
            <Tab.Content>
              <Tab.Pane eventKey="profile">
                <h4> <span className='joey-bold' >Department: 👉 </span> 3 Years</h4>
               
                <p className='mb-4 epche_overview_para font-19 pt-3 joey-regular'>
                The Bachelor of Business Administration (BBA) is a comprehensive three-year undergraduate program, aiming to equip students with essential knowledge and competencies for a successful career in the dynamic business world. <br />

                The curriculum covers key areas like finance, marketing, human resources, entrepreneurship, economics, and business ethics, offering a holistic understanding of the corporate landscape. Core subjects include Principles of Management, Marketing Management, Financial Accounting, Business Communication, and more. BBA programs provide flexibility for specializations such as Finance, Marketing, Human Resource Management, and Entrepreneurship. <br />

                Emphasizing practical learning, students engage in case studies, projects, internships, and industry interactions, enhancing problem-solving and decision-making skills. Internships and projects bridge the gap between theory and practice, offering valuable insights into various industries. <br />

                The BBA course trains students for organizational responsibilities, emphasizing both academic and practical business subjects. Soft skills development in communication, teamwork, leadership, and critical thinking is a key focus. <br />
                </p>
                <h4  className='joey-bold' > Eligibility : * </h4>
                <p className='mb-4 epche_overview_para font-19 pt-3 joey-bold'> * As notified by the Government of Karnataka and BNU from time to time. </p>
                <ul className='paralists_vis font-19'>
                    <li className='ms-3 pb-4' > Pass in 10+2 any discipline. </li>
                </ul>
                <h4  className='joey-bold' > Affiliation : </h4>
                <p className='mb-4 epche_overview_para font-19 pt-3 joey-regular'> 👉 Bengaluru North University (BNU) </p>
                <h4  className='joey-bold' > Approval : </h4>
                <p className='mb-4 epche_overview_para font-19 pt-3 joey-regular'> 👉 All India Council for Technical Education (AICTE) </p>
                <h4  className='joey-bold' > Recognition : </h4>
                <p className='mb-4 epche_overview_para font-19 pt-3 joey-regular'> 👉 Government of Karnataka (GoK) </p>
                <h4  className='joey-bold' > Career Opportunities : </h4>
                <p className='mb-4 epche_overview_para font-19 pt-3 joey-bold'> BBA graduates can explore diverse roles in marketing, finance, HR, operations, and entrepreneurship. The program offers a solid foundation for an MBA or direct entry into the workforce. Opportunities abound in sectors like banking, marketing, IT, business, insurance, and MNCs. Further specialization through CA, ICWA, CS, MBA, MFA, MIB, and MTA courses enhances career prospects. Job roles include Marketing Executive, Financial Analyst, HR Generalist, Operations Manager, Sales Executive, and Entrepreneur.</p>
              </Tab.Pane>
              
            </Tab.Content>
            </div>
          </Col>
        </Row>
      </Tab.Container>
                </Row>
                <Row className='gx-lg-5 pt-5' >
                            <Col xs={12} md={12} xl={4} className='my-4 my-xl-0 wayoflife_quotation_content'>
                                <img src={QuotationMarck} alt="EPCHE Way of Life" loading='lazy' className='w-100 wayoflife_quotation_mark' />
                                <h3 className='wayoflife_quotation_mark_heading'>Experience The East Point Way of Life</h3>
                                <button className="enquire-btn border-0 outline-0 "><a target="_blank" class="text-white" href="https://admissions.eastpoint.ac.in/">APPLY NOW</a>
                </button>
                            </Col>
                            <Col xs={12} md={12} xl={8}>
                                <img src={EPCHEWayOfLifeBBA} alt="EPCHE Way of Life" loading='lazy' className='w-100 epche_wayoflife_img' />
                            </Col>
                        </Row>
            </Container>
        </div>    
       </div> 
       <Footer /> 
    </div>
  )
}

export default epcheProgramsBbaBusiness