import React from 'react';
import { Container, Col, Row, Breadcrumb } from 'react-bootstrap';
import "../../styles/GlobalStyle.css";
import "../../styles/HomeStyle.css";
import Header from '../../components/Layouts/Header';
import EPCHEMenu from '../../components/Reusablecomponents/EpcheMenu';
import Footer from '../../components/Layouts/Footer';
import EPCHEOverviewVisson from "../../assets/epche/epche-overview-vismisimg.webp";
import EPCHEWayoflife8 from "../../assets/epche/epche_way_of_life_8.webp";
import QuotationMarck from "../../assets/epche/quotation-mark.webp";


function epcheOverview() {
  return (
    <div>
        <div className='main_body_bg_img'>
        <Header />
        <EPCHEMenu />
        <Container>
        <Breadcrumb>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item >
            East Point Institutions
            </Breadcrumb.Item>
            <Breadcrumb.Item active>East Point College of Higher Education (EPCHE) -
            Overview</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
            <Col xs={12} className='mb-4'>
                <h1>OVERVIEW</h1>
                <div className='border-bottom-pink my-2 my-lg-4'></div>
            </Col>
        </Row>
        </Container>
        <div className='bg-white'>
            <Container className='pb-5 overview'>
                <Row className='pb-5'>
                    <Col xs={12}>
                        <Row className='gx-lg-5 pt-5 pb-3'>
                            <Col xs={12} md={6} lg={4}>
                                <p className='mb-4 epche_overview_para'>
                                East Point College of Higher Education (EPCHE), established in 1999 by Dr.S.M.Venkatpathi under the Aegis of M. G. Charitable Trust in Bangalore, strong hold of academic excellence amidst the dynamic landscape of East Bangalore. Affiliated to Bengaluru North University (BNU), approved by AICTE and Government of Karnataka, EPCHE offers a diverse range of undergraduate, postgraduate programs in Management, Computer Applications and Commerce setting a benchmark for quality education.
                                </p>
                                <p className='mb-4 epche_overview_para'>
                                At the heart of EPCHE's success is its renowned faculty, comprising some of the brightest minds from across the nation. These experienced educators, many with PhDs, bring profound knowledge and engaging teaching styles to the classrooms, ensuring a top-notch educational experience.
                                </p>
                                <p className='mb-4 epche_overview_para'>
                                EPCHE boasts state-of-the-art infrastructure, including modern classrooms, seminar halls, well-equipped laboratories, and a Wi-Fi-enabled library
                                </p>
                            </Col>
                            <Col xs={12} md={6} lg={4}>
                            <p className='mb-4 epche_overview_para'>
                            housing an extensive collection of books and technical journals. The learning and resource center, with over 14,000 volumes of books and ties to prominent consortiums like DELNE, HELINET, INDEST-AICTE, and EDUSAT, ensures students have access to a wealth of information.
                            </p>
                            <p className='mb-4 epche_overview_para'>
                            EPCHE integrates academic excellence with real-world exposure; ensuring final-year students undergo enriching internships in renowned industries. Graduating students secure coveted placements on campus with well-known companies, including ICICI, HDFC, Cognizant, Infosys, Accenture, IBM, Capgemini, JPMorgan, Allstate, Tejas Network, and more. Some alumni’s have established startups in Bangalore and overseas, showcasing the institution's impact.
                            </p>
                            <p className='mb-4 epche_overview_para'>
                            Situated on a multi-disciplinary campus, students benefit from cross-disciplinary interactions with peers in medical, pharmacy, nursing, engineering, and science. The institution provides comprehensive
                            </p>
                            </Col>
                            <Col xs={12} md={12} lg={4}>
                            <p className='mb-4 epche_overview_para'>
                            facilities, including transportation, hostels, and sports amenities, ensuring a favourable environment for learning and personal growth.
                            </p>
                            <p className='mb-4 epche_overview_para'>
                            Departments contribute to holistic student development by offering competency and skill development courses in areas like WordPress, Python, Raspberry Pi with Arduino, Cloud Computing, Derivatives in Stock and Commodity Markets, Digital Marketing, TallyERP9 with GST filing, and others.
                            </p>
                            <p className='mb-4 epche_overview_para'>
                            Students actively engage in intercollegiate sports and cultural activities organized by BNU, both within and outside Karnataka. The vibrant campus life is enriched by over 15 student clubs, providing diverse opportunities for participation in various activities.
                            </p>
                            <p className='mb-4 epche_overview_para'>
                            In essence, EPCHE is dedicated to elevating students' satisfaction levels through a holistic approach to education, where academic excellence seamlessly intertwines with practical exposure and extracurricular engagement.
                            </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} lg={4}>
                                <h2 className='joey-bold font-34 m-0' >Vission</h2>
                                <p className='dept_vismis_para'>
                                The East Point College of Higher Education aspires to be a globally acclaimed institution, recognized for excellence in Science, Commerce, Management, and Computer Application education and applied research, and nurturing students for holistic development.
                                </p>
                            </Col>
                            <Col xs={12} lg={8}>
                                <img src={EPCHEOverviewVisson} className='w-100 overview_img' loading='lazy' alt='vision' />
                            </Col>
                            <Col xs={12} lg={4} className='pt-3'>
                            <h2 className='joey-bold font-34 m-0' >Mission</h2>
                            <p className='dept_vismis_para'>
                            Our purpose is to create commerce, management,
                                science, and computer application education graduates through quality education blended
                                with experiential learning. We are committed to innovation, creativity, and excellence
                                in our teaching, learning, and research pedagogy. We inspire integrity, teamwork,
                                critical thinking, personal development, ethics in our students and lay the foundation
                                for lifelong learning. We serve the scientific, technological, economic, and societal
                                developmental needs of our communities.
                            </p>
                            </Col>
                            <Col xs={12} lg={8} className='pt-3 paralists_vis'>
                            <h2 className='joey-bold font-34 m-0' >Objectives</h2>
                                <ol>
                                    <li className='ms-3 pb-4'>To disseminate knowledge and skills through instructions, teaching, training, seminars, workshops, and symposia in commerce, management, science and computer education and to equip students and scholars to meet the needs of industries, businesses, and society for its scientific, technological, and economic growth</li>
                                    <li className='ms-3 pb-4'>To generate knowledge in commerce, management, science and computer education through research to meet the challenges that arise in industry, business, and society</li>
                                    <li className='ms-3 pb-4'>To train our inputs on emotional, intellectual, societal, physical, environmental, economic, and spiritual dimensions</li>
                                    <li className='ms-3 pb-4'>To inculcate problem solving capabilities hence real-life problems posed by industry, business, and society in commerce management and computer shall be addressed.</li>
                                    <li className='ms-3 pb-4'>To enlighten the spirit of entrepreneurship in our young minds to help create more career opportunities the society by incubating and nurturing feasible business ideas with the advent of technological backbone</li>
                                    <li className='ms-3 pb-4'>To impart leadership skills in students and help them in the development of our future business leaders to enrich the society we live in</li>
                                    <li className='ms-3 pb-4'>To develop a partnership with universities, industries, businesses, research establishments, NGOs, international organizations, governmental organizations in India and abroad to enrich the experiences of faculties and students through research and developmental programs</li>
                                </ol>
                            </Col>
                        </Row>
                        <Row className='gx-lg-5 pt-5' >
                            <Col xs={12} md={12} xl={4} className='my-4 my-xl-0 wayoflife_quotation_content text-center'>
                                <img src={QuotationMarck} alt="EPCHE Way of Life" loading='lazy' className='w-100 wayoflife_quotation_mark' />
                                <h3 className='wayoflife_quotation_mark_heading'>Experience The East Point Way of Life</h3>
                                <button className="enquire-btn border-0 outline-0 "><a target="_blank" class="text-white" href="https://admissions.eastpoint.ac.in/">APPLY NOW</a>
                </button>
                            </Col>
                            <Col xs={12} md={12} xl={8}>
                                <img src={EPCHEWayoflife8} alt="EPCHE Way of Life" loading='lazy' className='w-100 epche_wayoflife_img' />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
        </div>
        <Footer/>
    </div>
  )
}

export default epcheOverview;