import React from 'react';
import { Container, Col, Row, Breadcrumb, Nav, Tab } from 'react-bootstrap';
import "../../styles/GlobalStyle.css";
import "../../styles/HomeStyle.css";
import Header from '../../components/Layouts/Header';
import EPCHEMenu from '../../components/Reusablecomponents/EpcheMenu';
import Footer from '../../components/Layouts/Footer';
import Slider from "react-slick";
import EPCHEWayOfLifeBBA4 from '../../assets/epche/epche_way_of_life_4.webp';
import QuotationMarck from "../../assets/epche/quotation-mark.webp";
import FootBallGroundOpen1 from '../../assets/epche/rae_bascatball_groundopen1.webp';
import FootBallGroundOpen2 from '../../assets/epche/rae_bascatball_groundopen2.webp';
import FootBallGroundOpen3 from '../../assets/epche/rae_bascatball_groundopen3.webp';
import WhatshppingGraduationDay1 from '../../assets/epche/whatshpping_graduationaday_celebration1.webp';
import WhatshppingGraduationDay2 from '../../assets/epche/whatshpping_graduationaday_celebration2.webp';
import WhatshppingGraduationDay3 from '../../assets/epche/whatshpping_graduationaday_celebration3.webp';
import WhatshppingGraduationDay4 from '../../assets/epche/whatshpping_graduationaday_celebration4.webp';
import WhatshppingFootballMtchWin1 from '../../assets/epche/whatshpping_football_matchwin1.webp';
import WhatshppingFootballMtchWin2 from '../../assets/epche/whatshpping_football_matchwin2.webp';


function epcheWhatsHappening() {
    const settings = {
        infinite: true,
        autoplay: true,
        speed: 5000,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
      };

  return (
    <div>
        <div className='main_body_bg_img'>
        <Header />
        <EPCHEMenu />
        <Container>
        <Breadcrumb>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item >
            East Point Institutions
            </Breadcrumb.Item>
            <Breadcrumb.Item active>East Point College of Higher Education (EPCHE) -
            What’s Happening?</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
            <Col xs={12} className='mb-4'>
                <h1> What’s Happening? </h1>
                <div className='border-bottom-pink my-2 my-lg-4'></div>
            </Col>
        </Row>
        </Container>
        <div className='bg-white'>
            <Container className='pb-5'>
                <Row className='pb-5'>
                    <Col xs={12} md={12} lg={5}>
                    <div className='whatshappingslider_mn_div'>
                    <div className="slider-container whatshapping_slider_div">
                        <Slider {...settings}>
                            <div className='whatshapping_slider_items'>
                            <img src={FootBallGroundOpen1} alt='East Point College FootBall Ground' className='w-100 whatshappingimgs' loading='lazy' />
                            </div>
                            <div className='whatshapping_slider_items'>
                            <img src={FootBallGroundOpen2} alt='East Point College FootBall Ground' className='w-100 whatshappingimgs' loading='lazy' />
                            </div>
                            <div className='whatshapping_slider_items'>
                            <img src={FootBallGroundOpen3} alt='East Point College FootBall Ground' className='w-100 whatshappingimgs' loading='lazy' />
                            </div>
                        </Slider>
                        </div>
                    </div>
                    </Col>
                    <Col xs={12} md={12} lg={7} className='mt-2 mt-lg-0'>
                        <div className='whatshappingslider_content_mn_div pt-5'>
                            <h5 className='joey-bold font-24'> The inauguration of the Football & Basketball Court at East Point "Jnanaprabha" campus </h5>
                            <p className='mb-4 epche_overview_para font-19 pt-3 joey-regular'>
                            The inauguration of the Football & Basketball Court at East Point "Jnanaprabha" campus, Bangalore took place on February 22nd, 2024.
                            Smt. B.L. Ramadevi Venkatpathi, our beloved Chairperson, inaugurated the court. The ceremony was graced by Sri S.V. Pramod Gowda, Trustee & CEO, EPGI, Sri S.V. Rajiv Gowda, Trustee & CEO, EPGI, Dr. Prakash S, Sr. Vice President, EPGI and all the Heads of the Institutions, Teaching, non-teaching and students.
                            The event brought together students, faculty, and guests in a jubilant atmosphere, reflecting EPGI's ethos of unity. The unveiling of the new facilities was met with enthusiasm, embodying the excitement within the community.
                            Every court is a platform for greatness, where playing with passion and purpose helps to establish a legacy of excellence. Together, let's inspire the next generation of athletes and forge lasting memories on these courts.
                            </p>
                        </div>
                    </Col>
               </Row>
               <Row className='pb-5'>
                    <Col xs={12} md={12} lg={5}>
                    <div className='whatshappingslider_mn_div'>
                    <div className="slider-container whatshapping_slider_div">
                        <Slider {...settings}>
                            <div className='whatshapping_slider_items'>
                            <img src={WhatshppingGraduationDay1} alt='East Point College Graduation Day' className='w-100 whatshappingimgs' loading='lazy' />
                            </div>
                            <div className='whatshapping_slider_items'>
                            <img src={WhatshppingGraduationDay2} alt='East Point College Graduation Day' className='w-100 whatshappingimgs' loading='lazy' />
                            </div>
                            <div className='whatshapping_slider_items'>
                            <img src={WhatshppingGraduationDay3} alt='East Point College Graduation Day' className='w-100 whatshappingimgs' loading='lazy' />
                            </div>
                            <div className='whatshapping_slider_items'>
                            <img src={WhatshppingGraduationDay4} alt='East Point College Graduation Day' className='w-100 whatshappingimgs' loading='lazy' />
                            </div>
                        </Slider>
                        </div>
                    </div>
                    </Col>
                    <Col xs={12} md={12} lg={7} className='mt-2 mt-lg-0'>
                        <div className='whatshappingslider_content_mn_div pt-5'>
                            <h5 className='joey-bold font-24'> "You Did it !!! We are proud of you... Best wishes for a future filled with triumphs." </h5>
                            <p className='mb-4 epche_overview_para font-19 pt-3 joey-regular'>
                            The Graduation Day 2023 for MBA Graduates at East Point College of Higher Education was indeed a grand affair held at the Higher Education Block on December 20th, 2023, commencing at 11:00 AM. The ceremony was graced by distinguished guests, including Prof. S.R. Niranjana, Vice Chairman of the Karnataka State Higher Education Council and former Vice Chancellor of Gulbarga University, Kalaburgi, serving as the Chief Guest.
                            Smt. B.L. Ramadevi, our beloved Chairperson, EPGI, Sri S.V. Pramod Gowda, Sri S.V. Rajiv Gowda, Trustees & CEOs, EPGI, Sri Peter Francis, Secretary, M.G. Charitable Trust, Bangalore, and Dr. Prakash S, Sr. Vice President of EPGI graced the occasion. It was truly a day to commemorate the academic journey of the graduating class and celebrate their achievements, with these esteemed personalities adding to the significance of the occasion
                            </p>
                        </div>
                    </Col>
               </Row>
               <Row className='pb-5'>
                    <Col xs={12} md={12} lg={5}>
                    <div className='whatshappingslider_mn_div'>
                    <div className="slider-container whatshapping_slider_div">
                        <Slider {...settings}>
                            <div className='whatshapping_slider_items'>
                            <img src={WhatshppingFootballMtchWin1} alt='East Point College FootBall Ground' className='w-100 whatshappingimgs' loading='lazy' />
                            </div>
                            <div className='whatshapping_slider_items'>
                            <img src={WhatshppingFootballMtchWin2} alt='East Point College FootBall Ground' className='w-100 whatshappingimgs' loading='lazy' />
                            </div>
                            
                        </Slider>
                        </div>
                    </div>
                    </Col>
                    <Col xs={12} md={12} lg={7} className='mt-2 mt-lg-0'>
                        <div className='whatshappingslider_content_mn_div pt-5'>
                            <h5 className='joey-bold font-24'> Champions on the rise!!! </h5>
                            <p className='mb-4 epche_overview_para font-19 pt-3 joey-regular'>
                            Students from East Point College of Engineering and Technology (EPCET) and East Point College of Higher Education (EPCHE) participated in "SPARDHA 23," the State Level Intercollegiate Sports Fest hosted by CMR Institute of Technology, Bangalore, on December 14th, 2023, and emerged as the champions in the Throwball Tournament!
                            The management, Heads of the Institutions, and all the teaching & non-teaching staff at East Point congratulated the team for their outstanding achievement.
                            </p>
                        </div>
                    </Col>
               </Row>
               <Row className='gx-lg-5 pt-5' >
                            <Col xs={12} md={12} xl={4} className='my-4 my-xl-0 wayoflife_quotation_content'>
                                <img src={QuotationMarck} alt="EPCHE Way of Life" loading='lazy' className='w-100 wayoflife_quotation_mark' />
                                <h3 className='wayoflife_quotation_mark_heading'>Experience The East Point Way of Life</h3>
                                <button className="enquire-btn border-0 outline-0 "><a target="_blank" class="text-white" href="https://admissions.eastpoint.ac.in/">APPLY NOW</a>
                </button>
                            </Col>
                            <Col xs={12} md={12} xl={8}>
                                <img src={EPCHEWayOfLifeBBA4} alt="EPCHE Way of Life" loading='lazy' className='w-100 epche_wayoflife_img' />
                            </Col>
                        </Row>
            </Container>
            </div>
        </div>
         <Footer /> 
    </div>
  )
}

export default epcheWhatsHappening