import React from 'react';
import { Container, Col, Row} from 'react-bootstrap';
import Slider from "react-slick";
import TEDx from "../../assets/hmpg_newspaper_sliders/ted-x-1.webp";
import ENewspaper1 from "../../assets/hmpg_newspaper_sliders/media-img-31may.webp";
import ENewspaper2 from "../../assets/hmpg_newspaper_sliders/media-img-1.webp";
import ENewspaper3 from "../../assets/hmpg_newspaper_sliders/media-img-2.webp";
import ENewspaper4 from "../../assets/hmpg_newspaper_sliders/media-img-3.webp";
import ENewspaper5 from "../../assets/hmpg_newspaper_sliders/9mrch24_medianwsppr.webp";
import ENewspaper6 from "../../assets/hmpg_newspaper_sliders/media_22may24-4.webp";
import ENewspaper7 from "../../assets/hmpg_newspaper_sliders/kannada_vijayvani_news paper14jn24.webp";
import ENewspaper8 from "../../assets/hmpg_newspaper_sliders/media_22may24-1.webp";
import ENewspaper9 from "../../assets/hmpg_newspaper_sliders/media_22may24-3.webp";
import ENewspaper10 from "../../assets/hmpg_newspaper_sliders/newspaper2_aug24.webp";
import ENewspaper11 from "../../assets/hmpg_newspaper_sliders/newspaper1_aug24.webp";
import ENewspaper12 from "../../assets/hmpg_newspaper_sliders/media_22may24-2.webp";
import ENewspaper13 from "../../assets/hmpg_newspaper_sliders/media_22may24-5.webp";


const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
  };

function Section5() {
  return (
    <div className='hmpg_news_slider_mn_container'>
    <Container className='mycontainer'>
      <Row>
        <Col lg={6}>  
            {/* news paper slider start  */}
             <div className='enewspaper_container'>
             <div className="slider-container">
                <Slider {...settings}>
                    <div className='item'>
                        <img src={ENewspaper1} alt='East Point News' className='img1' loading="lazy" />
                    </div>
                    <div className='item'>
                        <img src={ENewspaper2} alt='East Point News' className='img2' loading="lazy" />
                    </div>
                    <div className='item'>
                        <img src={ENewspaper3} alt='East Point News' className='img3' loading="lazy" />
                    </div>
                    <div className='item'>
                        <img src={ENewspaper4} alt='East Point News' className='img4' loading="lazy" />
                    </div>
                    <div className='item'>
                        <img src={ENewspaper5} alt='East Point News' className='img5' loading="lazy" />
                    </div>
                    <div className='item'>
                        <img src={ENewspaper6} alt='East Point News' className='img6' loading="lazy" />
                    </div>
                    <div className='item'>
                        <img src={ENewspaper7} alt='East Point News' className='img7' loading="lazy" />
                    </div>
                    <div className='item'>
                        <img src={ENewspaper8} alt='East Point News' className='img8' loading="lazy" />
                    </div>
                    <div className='item'>
                        <img src={ENewspaper9} alt='East Point News' className='img9' loading="lazy" />
                    </div>
                    <div className='item'>
                        <img src={ENewspaper10} alt='East Point News' className='img10' loading="lazy" />
                    </div>
                    <div className='item'>
                        <img src={ENewspaper11} alt='East Point News' className='img11' loading="lazy" />
                    </div>
                    <div className='item'>
                        <img src={ENewspaper12} alt='East Point News' className='img12' loading="lazy" />
                    </div>
                    <div className='item'>
                        <img src={ENewspaper13} alt='East Point News' className='img13' loading="lazy" />
                    </div>
                </Slider>
                </div>
             </div>


            {/* news paper slider end here */}
        
        <div className='socialmedias_sliders_heading'>
                      <h5>Media</h5>
        </div>
        </Col>
        <Col lg={6}> 
        <div className='hmpg_news_slider_tedximg_container'>
            <img src={TEDx} alt='TEDx in EPGI' className="w-100" loading="lazy" />
        </div>
        
        <div className='socialmedias_sliders_heading'>
                      <h5>Tedx @EPGI</h5>
        </div>
        </Col>
        </Row>
        </Container>
    </div>
  )
}

export default Section5