import React from 'react';
import VideoPlayer from './Videoplayer';
import Section1 from './Section1';
import Section2 from './Section2';

function Sections() {
  return (
    <>
        <VideoPlayer />
        <Section1 />
        <Section2 />
    </>
  )
}

export default Sections;