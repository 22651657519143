import React from 'react';
import "../../styles/HeaderStyle.css";

function Announcement() {
  return (
    <div className="horizontal-marquee">
    <div class="ancmnt_blink"><span>ANNOUNCEMENT</span></div>
    <div className="marquee-content">
      <div>👉 🌍 Day 1: Experts at National Conference tackling pollution's health and environmental impact! 🌱</div>
      <div>👉 "Exciting industry visit at Microsoft! 🚀 Students explore AI & Copilot! 🤖✨ #TechFuture"</div>
      <div>👉  Sri Rajiv Gowda shines at VK Chancellors Conclave, shaping education's future! 🎓✨</div>
      <div>👉  East Point College of Pharmacy awarded NAAC Accreditation for excellence! 📚</div>
      <div>👉  Onam Celebrations at EPGI filled with joy, colors, and traditions! 🌸🌼💃🎉🎊 </div>
      <div>👉  Discover Why East Point is Bangalore's Top Education Choice! 📚 Exclusive on Public TV, 14th July </div>
      <div>👉  Congratulations to East Point Nursing Students for winning the HKBK Intercollegiate Football Tournament! 🏆⚽ Keep shining brightly! 🌟 </div>
      <div>👉  EPCN wins RGUHS State Football Championship! 🎉👏⚽️ Mandya, 2024</div>
      <div>👉 🎓 EPCMS Graduation Day 2024 🎉 Celebrated with pride & joy! Honored to have Dr. K.S. Ravindranath 🎖</div>
      <div>👉 🎉💫 Mesmerizing Group dance at Samskruthi 2024! 💃</div>
    </div>
  </div>
  )
}

export default Announcement;