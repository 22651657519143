import React from 'react';
import { Container, Col, Row, Breadcrumb } from 'react-bootstrap';
import "../../styles/GlobalStyle.css";
import "../../styles/HomeStyle.css";
import Header from '../../components/Layouts/Header';
import EPCHEMenu from '../../components/Reusablecomponents/epcheMenu';
import Footer from '../../components/Layouts/Footer';
import EPCHEPrincipalImg from "../../assets/epche/EPCHE_Principal_Janet_Mary_Coates.webp";

function epcheMessage() {
  return (
    <div>
       <div className='main_body_bg_img'>
        <Header />
        <EPCHEMenu />
        <Container>
        <Breadcrumb>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item >
            East Point Institutions
            </Breadcrumb.Item>
            <Breadcrumb.Item active>East Point College of Higher Education (EPCHE) -
            Principal's Message</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
            <Col xs={12} className='mb-4'>
                <h1>PRINCIPAL'S MESSAGE</h1>
                <div className='border-bottom-pink my-2 my-lg-4'></div>
            </Col>
        </Row>
        </Container>
        <div className='bg-white'>
            <Container className='pb-5 overview'>
                <Row className='pb-5'>
                    <Col xs={12}>
                      <Row className='gx-lg-5 py-5 justify-content-center principal_main_row'>
                        <Col xs={12} lg={8} order={{ lg: 1 }}>
                          <p className="joey-regular font-19 pt-3 epche_overview_para"> With great pleasure, I welcome you to East Point College of Higher Education (EPCHE), a dedicated institution committed to nurture the leaders of tomorrow. As we commence this educational journey, Martin Luther King, Jr.'s words resonate: 'The function of education is to teach one to think intensively and to think critically. Intelligence plus character – that is the goal of true education.'<br /><br />

                              EPCHE proudly upholds a commitment to academic excellence and holistic development. Our comprehensive undergraduate and postgraduate courses in Business Administration, Computer Applications, and Commerce are meticulously crafted to meet evolving professional needs.<br /><br />

                              Our philosophy at East Point transcends imparting knowledge; it encompasses nurturing character, instilling values, and fostering innovation. We create an environment promoting critical thinking, creativity, and a passion for continuous learning. Our distinguished faculty, blending academic scholars and industry experts, ensures a learning experience extending beyond textbooks into real-world applications.<br /><br />

                              Recognizing the significance of a well-rounded education, East Point's vibrant campus offers myriad opportunities for extracurricular activities. Students are encouraged to engage in clubs, sports, cultural events, and community service initiatives, fostering personal growth and social responsibility.<br /><br />

                              Embark on this journey of knowledge and self-discovery at East Point. Together, let's shape a future where excellence is not merely a goal but a way of life.<br />
                              <br /></p>
                        </Col>
                        <Col xs={8} lg={4} order={{ lg: 2 }} className='principal_epche_img_div text-center pb-2'>
                        <img src={EPCHEPrincipalImg} className='w-100 principal_image_institutions' loading='lazy' alt='East Point College Principal Image' />
                        <h5>Dr. Janet Mary Coates</h5>
                        <div className="epgi_dept_pri_name">
                            Principal, East Point College of Higher Education <br />
                            <a href="mailto:Principal.epche@eastpoint.ac.in">principal.epche@eastpoint.ac.in</a><br />
                        </div>
                        </Col> 
                      </Row>
                    </Col>
                 </Row>
            </Container>  
         </div>         
        </div>
        <Footer />
    </div>
  )
}

export default epcheMessage