import React from 'react';
import EPCHEMenu from '../../components/Reusablecomponents/epcheMenu';
import Header from '../../components/Layouts/Header';
import Footer from '../../components/Layouts/Footer';
import Slider from "react-slick";
import { Container, Col, Row } from 'react-bootstrap';
import EPCHEBanner1 from "../../assets/epche/epche_bannernew-1.webp";
import EPCHEBanner2 from "../../assets/epche/EPCHE-2.webp";
import EPCHEBanner3 from "../../assets/epche/EPCHE-9.webp";
import EPCHEClgNews1 from "../../assets/epche/epche_clg_news_1.webp";
import EPCHEClgNews2 from "../../assets/epche/epche_clg_news_2.webp";
import EPCHEClgNews3 from "../../assets/epche/epche_clg_news_3.webp";
import InstitutionsHMPGWhatsapp_Icon from "../../assets/epche/whatsapp.webp";
import InstitutionsHMPGEnquiryNow from "../../assets/epche/enquire-now.webp";
import InstitutionsSupportIcons from "../../assets/epche/support.webp";

function institutionsEpcheHome() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
  const settingsSecond = {
    infinite: true,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 4000,
    slidesToShow: 2,
    slidesToScroll: 2,
    arrows: false
  };
  return (
    <div className='main_body_bg_img'>
      <Header />
      <EPCHEMenu className="epche-menu" />
      <Container className='position-relative'>
        <Row>
          <Col xs={12} >
          <div className="slider-container epche_homepage_banner_slider_container">
              <Slider {...settings}>
                <div>
                  <img src={EPCHEBanner1} alt='East Point College of Higher Education' className='w-100' />
                </div>
                <div>
                  <img src={EPCHEBanner2} alt='East Point College of Higher Education' className='w-100' />
                </div>
                <div>
                  <img src={EPCHEBanner3} alt='East Point College of Higher Education' className='w-100' />
                </div>
                
              </Slider>
            </div>
          </Col>
          <div xs={12} xl={4} className="apply-now-section position-absolute">
                    <button className="border-0 joey-bold font-18 text-white pink-bg apply-now-btn outline-0 my-4"><a
                            target="_blank" className="text-white" href="https://admissions.eastpoint.ac.in/">APPLY
                            NOW</a></button>

                </div>
        </Row>
        {/* buttons for inner pages whatsapp and enquirenow */}
        <div className="Institutions_whatsapp">
            <a href="https://wa.me/91 72042 29999" target="_blank">
                <img src={InstitutionsHMPGWhatsapp_Icon} alt="Whatsapp" className=""
                    loading="lazy" /></a>
        </div>

        <div className="Institutions_enquire-now">
            <button class="border-0 outline-0 " data-bs-toggle="modal" data-bs-target="#exampleModal">

                <img src={InstitutionsHMPGEnquiryNow} alt="Enquire Now" className=""
                    loading="lazy" /></button>
        </div>
        <div className="Institutions_admission-helpline p-2">
            <img src={InstitutionsSupportIcons} alt="Support" className="float-start"
                loading="lazy" />
            <div className="font-17">
                <p className="joey-bold mb-0 text-white">Admissions Helpline</p>
                <p className="mb-0"><a class="text-white" href="tel:+91720422999">+91 72042 29999</a></p>
            </div>
        </div>
      </Container>
      <Container>
        <Row>
          <h5 className='institutions_homepage_slider_heading'>College News</h5>
          <Col xs={12}>
          <div className="slider-container epche_homepage_college_news_slider_container">
              <Slider {...settingsSecond}>
              <div>
                  <img src={EPCHEClgNews1} alt='East Point College of Higher Education' className='w-100' />
                </div>
                <div>
                  <img src={EPCHEClgNews2} alt='East Point College of Higher Education' className='w-100' />
                </div>
                <div>
                  <img src={EPCHEClgNews3} alt='East Point College of Higher Education' className='w-100' />
                </div>
                
              </Slider>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
    
  )
}

export default institutionsEpcheHome