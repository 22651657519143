import React,{useState} from 'react';
import "../../styles/HeaderStyle.css";
import { Container, Nav, Navbar, NavDropdown  } from 'react-bootstrap';
import { Link } from "react-router-dom";
// import Button from 'react-bootstrap/Button';
import Epgilogo from "../../assets/logos/epgi_logo_withou25.png";
import Announcement from '../../pages/Home/Announcement';


function Header() {
    const [nav, setNav] = useState(false);

    // Scroll Navbar
    const changeValueOnScroll = () => {
        const scrollValue = document?.documentElement?.scrollTop;
        scrollValue > 100 ? setNav(true) : setNav(false);
    };
    window.addEventListener("scroll", changeValueOnScroll);

  return (
    <div>
    <Announcement />
    <header>
    {/* menu start from here */}
    <Navbar expand="lg" className={`${nav === true ? "sticky" : "" }`} >
      <Container fluid>
        <Navbar.Brand href="#">
            <Link to="/" className='epgilogo' >
            <img src={Epgilogo} alt='East Point Logo' className='img-fluid' />
            </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="ms-auto my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
            <Nav.Link as={Link} to="/introduction" >About Us</Nav.Link>
            <Nav.Link as={Link} to="/epche-home" >Our Institutions</Nav.Link>
            <NavDropdown title="Services" id="navbarScrollingDropdown">
              <NavDropdown.Item as={Link} to="/" >Courses</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/">
                Service 2
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item as={Link} to="/">
                Service new
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link as={Link} to="/courses" >Admissions</Nav.Link>
            <Nav.Link as={Link} to="/contact" >Placement</Nav.Link>
            <Nav.Link as={Link} to="/contact" >Why East Point</Nav.Link>
            <Nav.Link as={Link} to="/contact" >Campus Life</Nav.Link>
            <Nav.Link as={Link} to="/contact" >Connect</Nav.Link>
            
          </Nav>
          
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </header>
    </div>
  )
}

export default Header;