// Placement @ EPGI

import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import PlacemntlogosAtEpgi from "../../assets/hmpg_placement@epgi/placement-companies-logo-1_slideinone.webp";
// import {PlacemntBGimgAtEpgi} from "../../assets/hmpg_placement@epgi/placement-section-background.webp";
import PlacemntStudentAtEpgi from "../../assets/hmpg_placement@epgi/student-placement-image.webp";

function Section3() {
  return (
    <div className='whitebackground_container container py-5'>
        
    <div className='hmpg_plcmntepgi_mnsection'>
        
        <Container >
      <Row>
            <Col sm={8} lg={8}>
            <h2 className="hmpg_headings_h2">Placement @ EPGI</h2>
            <img src={PlacemntlogosAtEpgi} alt="Placement Logo at EPGI" className="w-100" loading="lazy" />
            </Col>
            <Col sm={4} lg={4}>
            <img src={PlacemntStudentAtEpgi} alt="Students at EPGI" className="w-100 plcmntstdntimg" loading="lazy" />
            </Col>
        </Row>
        </Container>
    </div>
   
    </div>
  )
}

export default Section3;